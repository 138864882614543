(function($, undefined) {

    $.nette.ext('spinner', {
        init: function () {

        },
        start: function () {
            this.counter++;
            if (this.counter === 1) {
                this.spinner.show(this.speed);
            }
        },
        complete: function () {
            this.counter--;
            if (this.counter <= 0) {
                this.spinner.hide(this.speed);
            }
        }
    }, {
        spinner: $('.ajax-loading'),
        speed: undefined,
        counter: 0
    });

})(jQuery);
